import axios from 'axios'

export default {
  fetchClients () {
    return axios.get('clients?sort=-id&expand=stateRel,resultRel,timetables,expired,course').then(response => {
      return response.data
    })
  },

  fetchClientsLookup () {
    return axios.get('clients?fields=id,name&sort=name').then(response => {
      return response.data
    })
  },

  fetchBirthdaysClients () {
    return axios.get('clients/birthdays').then(response => {
      return response.data
    })
  },

  fetchClientsByTimetableId (timetableId, activeAtDate) {
    return axios.get('clients?sort=name&expand=activeSubscriptions,activeSubscriptions.title,activeSubscriptions.extendedProps&status=1&timetable_id=' + timetableId + '&activeAtDate=' + activeAtDate).then(response => {
      return response.data
    })
  },

  fetchClientsResources () {
    return axios.get('clients/resources?status=1&sort=name').then(response => {
      return response.data
    })
  },

  fetchClientsByTag (tag) {
    return axios.get('clients?sort=-id&tag=' + tag).then(response => {
      return response.data
    })
  },

  fetchClientStates () {
    return axios.get('clientstates').then(response => {
      return response.data
    })
  },

  fetchClientResults () {
    return axios.get('clientresults').then(response => {
      return response.data
    })
  },

  fetchClientChannels () {
    return axios.get('clientchannels').then(response => {
      return response.data
    })
  },

  fetchClientChannelsStat (days) {
    return axios.get('clientchannels/stat?days=' + days).then(response => {
      return response.data
    })
  },

  fetchClient (id) {
    return axios.get('clients/' + id +
      '?expand=timetableIds,timetables,subscriptions,subscriptions.timetable,subscriptions.balance,subscriptions.lessons,stateLogs,stateLogs.oldStateRel,stateLogs.newStateRel,paymentsAndBills,balance')
      .then(response => {
        return response.data
      })
  },

  fetchClientName (id) {
    return axios.get('clients/' + id +
      '?fields=id,name,parents')
      .then(response => {
        return response.data
      })
  },

  updateClient (client) {
    return axios.put('clients/' + client.id +
      '?expand=timetableIds,timetables,subscriptions,subscriptions.timetable,subscriptions.balance,subscriptions.lessons,stateLogs,stateLogs.oldStateRel,stateLogs.newStateRel,paymentsAndBills,balance', client)
      .then(response => {
        return response.data
      })
  },

  createClient (client) {
    return axios.post('clients', client).then(response => {
      return response.data
    })
  },
}
