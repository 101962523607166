<template>
  <v-container
    id="clientslist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Клиенты
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Список всех клиентов. Выберите 'Новые' для отображения клиентов, которым нужно назначить пробное занятие и продать абонемент. Для получения списка клиентов у которых скоро заканчивается абонемент выберите 'На продление'. Также можно использовать поиск по имени или отобразить клиентов с другими статусами.</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-row>
                  <v-col
                    class="pa-0 ma-0"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск по фамилии, номеру телефона, email"
                      single-line
                      hide-details
                    />
                  </v-col>
                  <v-col
                    class="pa-0 ma-0"
                    cols="auto"
                  >
                    <v-icon
                      title="Очистить поиск"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="search = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="ageFilter"
                  color="secondary"
                  item-color="secondary"
                  label="Возраст"
                  item-value="age"
                  item-text="name"
                  multiple
                  item-disabled="none"
                  :items="ages"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-2 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          item-value="item.age"
                          return-object
                          v-text="item.name"
                        />
                      </v-list-item-content>

                      <v-scale-transition>
                        <v-list-item-icon
                          v-if="attrs.inputValue"
                          class="my-3"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                      </v-scale-transition>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="stateFilter"
                  color="secondary"
                  item-color="secondary"
                  label="Статус"
                  item-value="tag"
                  item-text="name"
                  multiple
                  item-disabled="none"
                  :items="states"
                  @change="onStateFilterChange($event)"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-2 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          item-value="item.id"
                          return-object
                          v-text="item.name"
                        />
                      </v-list-item-content>

                      <v-scale-transition>
                        <v-list-item-icon
                          v-if="attrs.inputValue"
                          class="my-3"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                      </v-scale-transition>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="resultFilter"
                  color="secondary"
                  item-color="secondary"
                  label="Причина отказа"
                  item-value="tag"
                  item-text="name"
                  multiple
                  :items="results"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-2 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          item-value="item.id"
                          return-object
                          v-text="item.name"
                        />
                      </v-list-item-content>

                      <v-scale-transition>
                        <v-list-item-icon
                          v-if="attrs.inputValue"
                          class="my-3"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                      </v-scale-transition>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="timetable"
                  label="Группа"
                  :items="timetables"
                  item-value="id"
                  item-text="name"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="course"
                  label="Интересующий курс"
                  :items="courses"
                  item-value="id"
                  item-text="name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="clientDialog = true"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyNew"
                  class="mt-0 mb-0"
                  :label="newCount"
                />
              </v-col>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyExpiredComputed"
                  class="mt-0 mb-0"
                  :label="expireCount"
                />
              </v-col>

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  label="Показать архивных"
                />
              </v-col>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="headers[8].visible"
                  class="mt-0 mb-0"
                  label="Отображать комментарии"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка списка клиентов..."
            :headers="filteredHeaders"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            @current-items="getFiltered"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :id="'client_' + item.id"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                >
                  <td @click="singleClient(item)">
                    {{ item.id }}
                  </td>
                  <td @click.self="singleClient(item)">
                    {{ item.parents }}
                    <v-btn
                      icon
                      :href="singleClientLink(item.id)"
                      target="_blank"
                      title="Открыть карточку клиента в новой вкладке"
                      class="mr-0 pr-0"
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </td>
                  <td @click="singleClient(item)">
                    <div v-if="item.phone">
                      {{ item.phone }}
                    </div>
                    <div v-if="item.email">
                      {{ item.email }}
                    </div>
                  </td>
                  <td @click="singleClient(item)">
                    {{ item.name }}
                  </td>
                  <td @click="singleClient(item)">
                    <span v-if="item.age || item.age === 0">
                      <v-chip>
                        {{ item.age }}
                      </v-chip>
                      {{ item.birthday | formatBirthday }}</span>
                  </td>
                  <td @click="singleClient(item)">
                    <v-chip
                      v-for="timetable in item.timetables"
                      :key="timetable.id"
                      class="group-chip"
                      color="pink lighten-5"
                    >
                      {{ timetable.name }}
                    </v-chip>
                    <v-chip
                      v-if="item.course"
                      :key="item.course.id"
                      class="group-chip"
                      color="purple lighten-5"
                    >
                      {{ item.course.name }}
                    </v-chip>
                  </td>
                  <td @click="singleClient(item)">
                    <span v-if="item.stateRel">{{ item.stateRel.name }}</span>
                  </td>
                  <td @click="singleClient(item)">
                    <span v-if="item.resultRel">{{ item.resultRel.name }}</span>
                  </td>
                  <td
                    v-if="headers[8].visible"
                    @click="singleClient(item)"
                  >
                    {{ item.comment }}
                  </td>
                  <td class="text-center">
                    {{ item.created | formatDateFromUnixTime }}
                  </td>
                  <td class="text-center">
                    {{ item.updated | formatDateFromUnixTime }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-if="item.status"
                      color="error"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Переместить в архив"
                      small
                      :disabled="loading"
                      @click="confirmArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!item.status"
                      color="info"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Вернуть из архива"
                      small
                      :disabled="loading"
                      @click="confirmUnArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-restore
                      </v-icon>
                    </v-btn>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </base-material-card>

        <v-dialog
          v-model="clientDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              Добавление нового клиента

              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="clientDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-5"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="client.parents"
                      required
                      label="ФИО родителя/опекуна"
                      :error-messages="fieldError('parents')"
                    />

                    <v-text-field
                      v-model="client.phone"
                      label="Телефон"
                      :error-messages="fieldError('phone')"
                    />

                    <v-text-field
                      v-model="client.name"
                      label="ФИО ребенка"
                      :error-messages="fieldError('name')"
                    />

                    <v-menu
                      ref="menu2"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(client.birthday)"
                          label="День рождения ребенка"
                          :error-messages="fieldError('birthday')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="client.birthday"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="menu3 = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="menu3 = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                    <v-select
                      v-model="client.channel"
                      :error-messages="fieldError('channel')"
                      label="Канал"
                      :items="channels"
                      item-value="tag"
                      item-text="name"
                    />

                    <v-select
                      v-model="client.state"
                      label="Статус"
                      :items="states"
                      :error-messages="fieldError('state')"
                      item-value="tag"
                      item-text="name"
                    />
                    <v-row v-if="showTrialDate">
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-menu
                          ref="trialMenu2"
                          v-model="trialMenu"
                          :close-on-content-click="false"
                          :return-value.sync="trialDate"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              :value="formatDate(client.trial_date)"
                              label="Дата пробного занятия"
                              :error-messages="fieldError('trial_date')"
                              color="secondary"
                              prepend-icon="mdi-calendar-outline"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="client.trial_date"
                            color="secondary"
                            locale="ru-ru"
                            landscape
                            scrollable
                            first-day-of-week="1"
                            @input="trialMenu = false"
                          >
                            <v-spacer />

                            <v-btn
                              color="secondary"
                              large
                              @click="trialMenu = false"
                            >
                              Cancel
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                color="secondary"
                rounded
                min-width="100"
                :disabled="loading"
                @click="create()"
              >
                Далее
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Переместить клиента в архив?
        </v-card-title>

        <v-card-text>
          После помещения в архив клиент не будет отображаться в учебном процессе.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="archive(selectedClient)"
          >
            Переместить в архив
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUnArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Вернуть клиента из архива?
        </v-card-title>

        <v-card-text>
          После возврата клиента из архива он снова будет отображаться в учебном процессе.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmUnArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="unarchive(selectedClient)"
          >
            Вернуть из архива
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import clientsApi from '@/services/ClientsApi'
  import timetableApi from '@/services/TimetablesApi'
  import moment from 'moment'
  import clientApi from '../services/ClientsApi'
  import coursesApi from '../services/CoursesApi'
  import CRMStorage from '../services/CRMStorage'
  import { hasRoles, HomeTeacherAdmin } from '../services/jwt.service'

  export default {
    filters: {
      formatBirthday: function (date) {
        return moment(date).format('DD.MM.YYYY')
      },
      formatDateFromUnixTime: function (unixtime) {
        if (unixtime) { return moment.unix(unixtime).format('DD.MM.YY HH:mm') }
        return ''
      },
    },
    props: ['state'],
    data () {
      return {
        onlyArchived: CRMStorage.getItem('CL_onlyArchived', false),
        onlyExpired: CRMStorage.getItem('CL_onlyExpired', false),
        showComments: CRMStorage.getItem('CL_showComments', false),
        stateFilter: CRMStorage.getItem('CL_stateFilter', []),
        resultFilter: CRMStorage.getItem('CL_resultFilter', []),
        timetable: CRMStorage.getItem('CL_timetable', null),
        course: CRMStorage.getItem('CL_course', null),
        search: CRMStorage.getItem('CL_search', ''),
        ageFilter: CRMStorage.getItem('CL_ageFilter', []),

        states: [],
        results: [],
        channels: [{ id: null, name: ' - не выбрано - ', tag: null }],
        timetables: [{ id: null, name: ' - Все - ', tag: null }],
        courses: [{ id: null, name: ' - Все - ', tag: null }],
        list: {
          0: false,
          1: false,
          2: false,
        },
        page: 1,
        itemsPerPage: CRMStorage.getItem('CL_itemsPerPage', 10),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
            visible: true,
          },
          {
            sortable: true,
            text: 'ФИО родителя',
            value: 'parents',
            visible: true,
          },
          {
            sortable: true,
            text: 'Телефон/Email',
            value: 'phone',
            visible: true,
          },
          {
            sortable: true,
            text: 'Имя ребенка',
            value: 'name',
            visible: true,
          },
          {
            text: 'Возраст',
            value: 'age',
            filter: (value) => {
              if (!Array.isArray(this.ageFilter) || this.ageFilter.length <= 0) return true
              return this.ageFilter.includes(value)
            },
            visible: true,
          },
          {
            sortable: false,
            text: 'Группа/Курс',
            value: 'timetables',
            filter: value => {
              if (!this.timetable) return true
              return value.filter(e => e.id === this.timetable).length > 0
            },
            visible: true,
          },
          {
            text: 'Статус',
            value: 'state',
            filter: value => {
              if (!Array.isArray(this.stateFilter) || this.stateFilter.length <= 0) return true
              return this.stateFilter.includes(value)
            },
            visible: true,
          },
          {
            text: 'Отказ',
            value: 'result',
            filter: value => {
              if (!Array.isArray(this.resultFilter) || this.resultFilter.length <= 0) return true
              return this.resultFilter.includes(value)
            },
            visible: true,
          },
          {
            text: 'Комментарий',
            value: 'comment',
            visible: false,
          },
          {
            sortable: true,
            text: 'Созд',
            value: 'created',
            visible: true,
          },
          {
            sortable: true,
            text: 'Изм',
            value: 'updated',
            visible: true,
          },
          {
            sortable: false,
            text: ' ',
            value: 'status',
            visible: true,
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
          {
            sortable: false,
            text: '',
            value: 'expired',
            visible: true,
            filter: value => {
              if (this.onlyExpired) {
                return value === true
              }
              return true
            },
          },
          {
            sortable: false,
            text: '',
            value: 'course_id',
            visible: true,
            filter: value => {
              if (!this.course) return true
              return value === this.course
            },
          },
          {
            sortable: false,
            text: '',
            value: 'email',
            visible: true,
          },
        ],
        items: [],
        tags: [],
        loading: true,
        clientDialog: false,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        client: {
          name: null,
          parents: null,
          office_id: null,
          birthday: null,
          state: 'new',
          trial_date: null,
        },
        menu3: false,
        trialMenu: false,
        date: '',
        trialDate: '',

        confirmArchiveDialog: false,
        confirmUnArchiveDialog: false,
        selectedClient: null,
      }
    },
    computed: {
      showTrialDate () {
        if (hasRoles([HomeTeacherAdmin])) return false
        return !!this.client.trial_date || this.client.state === 'awaiting_trial'
      },
      newCount () {
        if (this.items.length < 1) {
          return 'Новые (0)'
        }
        return 'Новые (' + this.items.filter((item) => item.state === 'new').length + ')'
      },
      expireCount () {
        if (this.items.length < 1) {
          return 'На продление (0)'
        }
        return 'На продление (' + this.items.filter((item) => item.expired === true).length + ')'
      },
      filteredHeaders () {
        return this.headers.filter(h => h.visible)
      },
      onlyNew: {
        get () {
          return this.compareArrays(this.stateFilter, ['new'])
        },
        set (checked) {
          this.stateFilter = checked ? ['new'] : []
          if (checked) this.onlyExpired = false
        },
      },
      onlyExpiredComputed: {
        get () {
          return this.onlyExpired
        },
        set (checked) {
          this.onlyExpired = checked
          if (checked) this.onlyNew = false
        },
      },
      ages () {
        let agesArray = []
        let currentYear = new Date().getFullYear()
        for (let age = 0; age < 19; age++) {
          let year = currentYear - age
          agesArray.push({ age: age, name: this.ageToStr(age) + ' (' + year + ' гр)' })
        }
        return agesArray
      },
    },
    watch: {
      onlyExpired: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_onlyExpired', val)
      },
      stateFilter: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_stateFilter', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_onlyArchived', val)
      },
      showComments: function (val) {
        CRMStorage.setItem('CL_showComments', val)
      },
      resultFilter: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_resultFilter', val)
      },
      timetable: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_timetable', val)
      },
      course: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_course', val)
      },
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('CL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('CL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('CL_page', val)
      },
      ageFilter: function (val) {
        CRMStorage.setItem('CL_ageFilter', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('CL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('CL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Клиенты | ' + process.env.VUE_APP_NAME
      // if (!this.itemsPerPage) this.itemsPerPage = 10
      // if (!this.page) this.page = 1

      clientsApi
        .fetchClientStates()
        .then(response => {
          this.states = this.states.concat(response)
          if (!hasRoles([HomeTeacherAdmin])) {
            this.states.forEach(function (item) {
              if (item.tag === 'awaiting_trial') item.disabled = true
            })
          }
        })
        .catch(error => {
          this.handleError(error)
        })

      clientsApi
        .fetchClientResults()
        .then(response => {
          this.results = this.results.concat(response)
        })
        .catch(error => {
          this.handleError(error)
        })

      timetableApi
        .fetchActiveTimetables()
        .then(response => {
          this.timetables = this.timetables.concat(response)
        })
        .catch(error => {
          this.handleError(error)
        })

      coursesApi
        .fetchCourses()
        .then(response => {
          this.courses = this.courses.concat(response)
        })
        .catch(error => {
          this.handleError(error)
        })

      clientApi
        .fetchClientChannels()
        .then(response => {
          this.channels = this.channels.concat(response)
        })
        .catch(error => {
          this.handleError(error)
        })

      clientsApi.fetchClients()
        .then(response => {
          this.items = this.items.concat(response)
          this.loading = false
        })
        .catch(error => {
          this.handleError(error)
        })
    },
    methods: {
      singleClient (client) {
        CRMStorage.setItem('CL_scrollY', window.scrollY)
        this.$router.push('/client/' + client.id)
      },
      singleClientLink (clientId) {
        return process.env.VUE_APP_BASE_URL + '/client/' + clientId
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      create () {
        this.loading = true
        this.error = null
        this.fieldsErrors = []

        clientApi
          .createClient(this.client)
          .then(response => {
            this.client = response
            this.clientDialog = false
            this.$router.push('/client/' + this.client.id)
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      update (client) {
        if (client.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          clientApi
            .updateClient(client)
            .then(response => {
              clientsApi.fetchClients()
                .then(response => {
                  this.items = this.items.concat(response)
                  this.loading = false
                })
                .catch(error => {
                  this.handleError(error)
                })
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },
      confirmArchive (client) {
        this.selectedClient = client
        this.confirmArchiveDialog = true
      },
      confirmUnArchive (client) {
        this.selectedClient = client
        this.confirmUnArchiveDialog = true
      },
      archive (client) {
        this.confirmArchiveDialog = false
        client.status = 0
        this.update(client)
      },
      unarchive (client) {
        this.confirmUnArchiveDialog = false
        client.status = 1
        this.update(client)
      },
      filterNew () {
        this.state = 'new'
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      onStateFilterChange (items) {
        if (this.compareArrays(items, ['new'])) {
          this.onlyNew = true
        }
      },
      compareArrays (first, second) {
        if (!first || !second) return false
        return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e))
      },
      handleError (errorResponse) {
        if (errorResponse && errorResponse.response) {
          let data = errorResponse.response.data
          if (errorResponse.response.status === 422) {
            this.fieldsErrors = data
          } else if (errorResponse.response.status === 401) {
            this.error = 'Неверный логин или пароль! Войдите в систему заново!'
          } else if (data.message) {
            this.error = data.message
          } else if (data) {
            this.error = JSON.stringify(data, null, 2)
          } else {
            this.error = 'Неизвестная ошибка системы. Попробуйте повторить запрос, а затем обратитесь на care@poliglotiki.ru.'
          }
        } else {
          this.error = 'Неизвестная ошибка системы. Попробуйте повторить запрос, а затем обратитесь на care@poliglotiki.ru.'
        }
        this.loading = false
      },

      getFiltered (items) {
        const selectedIds = items.map(({ id }) => id)
        CRMStorage.setItem('CL_clientsIds', selectedIds)
      },

      ageToStr (age) {
        let txt
        let count = age % 100
        if (count >= 5 && count <= 20) {
          txt = 'лет'
        } else {
          count = count % 10
          if (count === 1) {
            txt = 'год'
          } else if (count >= 2 && count <= 4) {
            txt = 'года'
          } else {
            txt = 'лет'
          }
        }
        return age + ' ' + txt
      },

    },
  }
</script>
