var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "clientslist", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "base-material-card",
                {
                  attrs: { color: "success", icon: "mdi-badge-account" },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "50%" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "card-title font-weight-light"
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Клиенты\n                "
                                          ),
                                          _c(
                                            "v-icon",
                                            { staticClass: "help-pointer" },
                                            [
                                              _vm._v(
                                                "\n                  mdi-help-circle-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Список всех клиентов. Выберите 'Новые' для отображения клиентов, которым нужно назначить пробное занятие и продать абонемент. Для получения списка клиентов у которых скоро заканчивается абонемент выберите 'На продление'. Также можно использовать поиск по имени или отобразить клиентов с другими статусами."
                                )
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label:
                                            "Поиск по фамилии, номеру телефона, email",
                                          "single-line": "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "help-pointer pt-5 pb-5 pl-2 pr-5",
                                          attrs: { title: "Очистить поиск" },
                                          on: {
                                            click: function($event) {
                                              _vm.search = ""
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    mdi-close\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  color: "secondary",
                                  "item-color": "secondary",
                                  label: "Возраст",
                                  "item-value": "age",
                                  "item-text": "name",
                                  multiple: "",
                                  "item-disabled": "none",
                                  items: _vm.ages
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var item = ref.item
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "mx-3 mb-2 v-sheet",
                                                attrs: {
                                                  "active-class":
                                                    "secondary elevation-4 white--text",
                                                  elevation: "0"
                                                }
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  attrs: {
                                                    "item-value": "item.age",
                                                    "return-object": ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.name
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-scale-transition",
                                              [
                                                attrs.inputValue
                                                  ? _c(
                                                      "v-list-item-icon",
                                                      { staticClass: "my-3" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-check")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.ageFilter,
                                  callback: function($$v) {
                                    _vm.ageFilter = $$v
                                  },
                                  expression: "ageFilter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  color: "secondary",
                                  "item-color": "secondary",
                                  label: "Статус",
                                  "item-value": "tag",
                                  "item-text": "name",
                                  multiple: "",
                                  "item-disabled": "none",
                                  items: _vm.states
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onStateFilterChange($event)
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var item = ref.item
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "mx-3 mb-2 v-sheet",
                                                attrs: {
                                                  "active-class":
                                                    "secondary elevation-4 white--text",
                                                  elevation: "0"
                                                }
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  attrs: {
                                                    "item-value": "item.id",
                                                    "return-object": ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.name
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-scale-transition",
                                              [
                                                attrs.inputValue
                                                  ? _c(
                                                      "v-list-item-icon",
                                                      { staticClass: "my-3" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-check")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.stateFilter,
                                  callback: function($$v) {
                                    _vm.stateFilter = $$v
                                  },
                                  expression: "stateFilter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  color: "secondary",
                                  "item-color": "secondary",
                                  label: "Причина отказа",
                                  "item-value": "tag",
                                  "item-text": "name",
                                  multiple: "",
                                  items: _vm.results
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var item = ref.item
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "mx-3 mb-2 v-sheet",
                                                attrs: {
                                                  "active-class":
                                                    "secondary elevation-4 white--text",
                                                  elevation: "0"
                                                }
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  attrs: {
                                                    "item-value": "item.id",
                                                    "return-object": ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.name
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-scale-transition",
                                              [
                                                attrs.inputValue
                                                  ? _c(
                                                      "v-list-item-icon",
                                                      { staticClass: "my-3" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-check")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.resultFilter,
                                  callback: function($$v) {
                                    _vm.resultFilter = $$v
                                  },
                                  expression: "resultFilter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Группа",
                                  items: _vm.timetables,
                                  "item-value": "id",
                                  "item-text": "name"
                                },
                                model: {
                                  value: _vm.timetable,
                                  callback: function($$v) {
                                    _vm.timetable = $$v
                                  },
                                  expression: "timetable"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "2" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Интересующий курс",
                                  items: _vm.courses,
                                  "item-value": "id",
                                  "item-text": "name"
                                },
                                model: {
                                  value: _vm.course,
                                  callback: function($$v) {
                                    _vm.course = $$v
                                  },
                                  expression: "course"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 pl-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: { color: "secondary", rounded: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.clientDialog = true
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n                  mdi-plus\n                "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n                Добавить\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "pt-0 pb-0" }),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: _vm.newCount },
                                model: {
                                  value: _vm.onlyNew,
                                  callback: function($$v) {
                                    _vm.onlyNew = $$v
                                  },
                                  expression: "onlyNew"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: _vm.expireCount },
                                model: {
                                  value: _vm.onlyExpiredComputed,
                                  callback: function($$v) {
                                    _vm.onlyExpiredComputed = $$v
                                  },
                                  expression: "onlyExpiredComputed"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: "Показать архивных" },
                                model: {
                                  value: _vm.onlyArchived,
                                  callback: function($$v) {
                                    _vm.onlyArchived = $$v
                                  },
                                  expression: "onlyArchived"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: "Отображать комментарии" },
                                model: {
                                  value: _vm.headers[8].visible,
                                  callback: function($$v) {
                                    _vm.$set(_vm.headers[8], "visible", $$v)
                                  },
                                  expression: "headers[8].visible"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loading,
                      "loading-text": "Загрузка списка клиентов...",
                      headers: _vm.filteredHeaders,
                      items: _vm.items,
                      search: _vm.search,
                      "items-per-page": _vm.itemsPerPage,
                      page: _vm.page
                    },
                    on: {
                      "update:itemsPerPage": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-items": _vm.getFiltered
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            _c(
                              "tbody",
                              _vm._l(items, function(item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    class: { archived: !item.status },
                                    attrs: { id: "client_" + item.id }
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.id) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            if (
                                              $event.target !==
                                              $event.currentTarget
                                            ) {
                                              return null
                                            }
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.parents) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-0 pr-0",
                                            attrs: {
                                              icon: "",
                                              href: _vm.singleClientLink(
                                                item.id
                                              ),
                                              target: "_blank",
                                              title:
                                                "Открыть карточку клиента в новой вкладке"
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-open-in-new")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        item.phone
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.phone) +
                                                  "\n                  "
                                              )
                                            ])
                                          : _vm._e(),
                                        item.email
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.email) +
                                                  "\n                  "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.name) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        item.age || item.age === 0
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-chip", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(item.age) +
                                                      "\n                    "
                                                  )
                                                ]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("formatBirthday")(
                                                        item.birthday
                                                      )
                                                    )
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._l(item.timetables, function(
                                          timetable
                                        ) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: timetable.id,
                                              staticClass: "group-chip",
                                              attrs: { color: "pink lighten-5" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(timetable.name) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        item.course
                                          ? _c(
                                              "v-chip",
                                              {
                                                key: item.course.id,
                                                staticClass: "group-chip",
                                                attrs: {
                                                  color: "purple lighten-5"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.course.name) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        item.stateRel
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.stateRel.name))
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleClient(item)
                                          }
                                        }
                                      },
                                      [
                                        item.resultRel
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(item.resultRel.name)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm.headers[8].visible
                                      ? _c(
                                          "td",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.singleClient(item)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.comment) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("formatDateFromUnixTime")(
                                              item.created
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("formatDateFromUnixTime")(
                                              item.updated
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-right" },
                                      [
                                        item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "error",
                                                  "min-width": "0",
                                                  title: "Переместить в архив",
                                                  small: "",
                                                  disabled: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirmArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-close\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "info",
                                                  "min-width": "0",
                                                  title: "Вернуть из архива",
                                                  small: "",
                                                  disabled: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirmUnArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-restore\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c("td"),
                                    _c("td"),
                                    _c("td")
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.clientDialog,
                    callback: function($$v) {
                      _vm.clientDialog = $$v
                    },
                    expression: "clientDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            "\n            Добавление нового клиента\n\n            "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.clientDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-5",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "ФИО родителя/опекуна",
                                          "error-messages": _vm.fieldError(
                                            "parents"
                                          )
                                        },
                                        model: {
                                          value: _vm.client.parents,
                                          callback: function($$v) {
                                            _vm.$set(_vm.client, "parents", $$v)
                                          },
                                          expression: "client.parents"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Телефон",
                                          "error-messages": _vm.fieldError(
                                            "phone"
                                          )
                                        },
                                        model: {
                                          value: _vm.client.phone,
                                          callback: function($$v) {
                                            _vm.$set(_vm.client, "phone", $$v)
                                          },
                                          expression: "client.phone"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "ФИО ребенка",
                                          "error-messages": _vm.fieldError(
                                            "name"
                                          )
                                        },
                                        model: {
                                          value: _vm.client.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.client, "name", $$v)
                                          },
                                          expression: "client.name"
                                        }
                                      }),
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu2",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.date,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.date = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.date = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.client.birthday
                                                          ),
                                                          label:
                                                            "День рождения ребенка",
                                                          "error-messages": _vm.fieldError(
                                                            "birthday"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menu3,
                                            callback: function($$v) {
                                              _vm.menu3 = $$v
                                            },
                                            expression: "menu3"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.menu3 = false
                                                }
                                              },
                                              model: {
                                                value: _vm.client.birthday,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.client,
                                                    "birthday",
                                                    $$v
                                                  )
                                                },
                                                expression: "client.birthday"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.menu3 = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Cancel\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "channel"
                                          ),
                                          label: "Канал",
                                          items: _vm.channels,
                                          "item-value": "tag",
                                          "item-text": "name"
                                        },
                                        model: {
                                          value: _vm.client.channel,
                                          callback: function($$v) {
                                            _vm.$set(_vm.client, "channel", $$v)
                                          },
                                          expression: "client.channel"
                                        }
                                      }),
                                      _c("v-select", {
                                        attrs: {
                                          label: "Статус",
                                          items: _vm.states,
                                          "error-messages": _vm.fieldError(
                                            "state"
                                          ),
                                          "item-value": "tag",
                                          "item-text": "name"
                                        },
                                        model: {
                                          value: _vm.client.state,
                                          callback: function($$v) {
                                            _vm.$set(_vm.client, "state", $$v)
                                          },
                                          expression: "client.state"
                                        }
                                      }),
                                      _vm.showTrialDate
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      ref: "trialMenu2",
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "return-value":
                                                          _vm.trialDate,
                                                        transition:
                                                          "scale-transition",
                                                        "min-width": "290px",
                                                        "offset-y": ""
                                                      },
                                                      on: {
                                                        "update:returnValue": function(
                                                          $event
                                                        ) {
                                                          _vm.trialDate = $event
                                                        },
                                                        "update:return-value": function(
                                                          $event
                                                        ) {
                                                          _vm.trialDate = $event
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        readonly:
                                                                          "",
                                                                        value: _vm.formatDate(
                                                                          _vm
                                                                            .client
                                                                            .trial_date
                                                                        ),
                                                                        label:
                                                                          "Дата пробного занятия",
                                                                        "error-messages": _vm.fieldError(
                                                                          "trial_date"
                                                                        ),
                                                                        color:
                                                                          "secondary",
                                                                        "prepend-icon":
                                                                          "mdi-calendar-outline"
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1104439962
                                                      ),
                                                      model: {
                                                        value: _vm.trialMenu,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.trialMenu = $$v
                                                        },
                                                        expression: "trialMenu"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-date-picker",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            locale: "ru-ru",
                                                            landscape: "",
                                                            scrollable: "",
                                                            "first-day-of-week":
                                                              "1"
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              _vm.trialMenu = false
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.client
                                                                .trial_date,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.client,
                                                                "trial_date",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "client.trial_date"
                                                          }
                                                        },
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                large: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.trialMenu = false
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            Cancel\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                color: "secondary",
                                rounded: "",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.create()
                                }
                              }
                            },
                            [
                              _vm._v("\n              Далее\n              "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(
                                  "\n                mdi-arrow-right\n              "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmArchiveDialog,
            callback: function($$v) {
              _vm.confirmArchiveDialog = $$v
            },
            expression: "confirmArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Переместить клиента в архив?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После помещения в архив клиент не будет отображаться в учебном процессе.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.archive(_vm.selectedClient)
                        }
                      }
                    },
                    [_vm._v("\n          Переместить в архив\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmUnArchiveDialog,
            callback: function($$v) {
              _vm.confirmUnArchiveDialog = $$v
            },
            expression: "confirmUnArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Вернуть клиента из архива?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После возврата клиента из архива он снова будет отображаться в учебном процессе.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmUnArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.unarchive(_vm.selectedClient)
                        }
                      }
                    },
                    [_vm._v("\n          Вернуть из архива\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }